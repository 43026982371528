import './navbar.css';
import logo from '../../assets/bifrustLogo.svg';
import { Button, Dropdown, Grid, Icon, Input, Message } from 'semantic-ui-react';
import React, { useState } from 'react';
import authUser from '../auth/authUser';

function Navbar() {
    const [scrollState, setScrollState] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const { http } = authUser();

    const changeNavbarBG = () => {
        const scrollValue = document.documentElement.scrollTop;
        if (scrollValue > 60) {
            setScrollState(true);
        } else {
            setScrollState(false);
        }
    };

    window.addEventListener('scroll', changeNavbarBG);

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('handleSubmit ran');
        
        // Clear any previous error message
        setErrorMsg('');

        http.post('https://bifrust.com/api/login', { email, password })
            .then((res) => {
                // Assuming the response contains a success status
                if (!res.error) {
                    // console.log(res.data);
                    // Redirect to bifrust.com on success
                    // window.location.href = 'https://bifrust.com';
                    // Create a form element dynamically
                    const form = document.createElement('form');
                    form.method = 'POST';
                    form.action = 'https://bifrust.com/login'; // The URL to redirect to

                    // Add the email field
                    const emailField = document.createElement('input');
                    emailField.type = 'hidden';  // Keep it hidden from the user
                    emailField.name = 'email';
                    emailField.value = email;  // Assuming email state is already set
                    form.appendChild(emailField);

                    // Add the password field
                    const passwordField = document.createElement('input');
                    passwordField.type = 'hidden';
                    passwordField.name = 'password';
                    passwordField.value = password;  // Assuming password state is already set
                    form.appendChild(passwordField);

                    // Append the form to the body and submit it
                    document.body.appendChild(form);
                    form.submit(); // This will POST to bifrust.com/login

                } else {
                    // If login failed, show the error message
                    setErrorMsg('Login failed. Please check your credentials.');
                }
            })
            .catch((error) => {
                console.error('Login error:', error);
                setErrorMsg('An error occurred. Please try again.');
            });
    };

    return (
        <div className="navbar">
            <header>
                <div className="nav">
                    <div className={scrollState ? "navScroll" : "nav"}>
                        <Grid verticalAlign="middle" centered columns={3}>
                            <Grid.Row className={scrollState ? "rowScroll" : ""} stretched>
                                <Grid.Column width={2}>
                                    <div className="navLogo">
                                        <img className={scrollState ? "navLogoImgScroll" : "navLogo"} src={logo} alt="logo" />
                                    </div>
                                </Grid.Column>
                                <Grid.Column verticalAlign="middle" width={8}>
                                    <ul className={scrollState ? "navULScroll" : ""}>
                                        {/* Navigation links */}
                                    </ul>
                                </Grid.Column>
                                <Grid.Column centered width={2}>
                                    <div className={scrollState ? "loginButtonScroll" : "loginButton"}>
                                        <Dropdown direction="left" icon="user">
                                            <Dropdown.Menu className="loginDropDown" onClick={(e) => e.stopPropagation()}>
                                                <form onSubmit={handleSubmit} id="login-form" className="loginForm">
                                                    <Input
                                                        placeholder="Email"
                                                        id="email"
                                                        type="text"
                                                        icon="user"
                                                        onChange={(event) => setEmail(event.target.value)}
                                                        name="email"
                                                    />
                                                    <Input
                                                        placeholder="Password"
                                                        icon="key"
                                                        id="password"
                                                        type="password"
                                                        name="password"
                                                        onChange={(event) => setPassword(event.target.value)}
                                                    />
                                                    <Button className="submitButton" type="submit">
                                                        Login
                                                    </Button>
                                                </form>

                                                {errorMsg && (
                                                    <Message negative>
                                                        <p className="bottomLinks">{errorMsg}</p>
                                                    </Message>
                                                )}

                                                <div className="bottomLinks">
                                                    <div>User registration will be available soon</div>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <div className="hamburgerButton">
                                            <Button>
                                                <Button.Content visible>
                                                    <Icon name="bars" />
                                                </Button.Content>
                                            </Button>
                                        </div>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </div>
            </header>
        </div>
    );
}

export default Navbar;
